<template>
  <div class="w-fit max-w-96 max-h-64">
    <img
      :src="defaultImg"
      v-show="!isLoaded"
      alt="Default image"
      class="object-contain w-96 h-64 max-h-fit p-6 k1:p-2 self-center"
    />
    <img
      :src="imageSrc"
      @load="handleLoad"
      @error="handleError"
      alt="Organisation logo"
      class="object-contain w-96 h-64 max-h-fit p-6 k1:p-2 self-center"
    />
  </div>
</template>

<script>
import { onMounted, ref } from "vue";

export default {
  props: {
    organisationImg: {
      type: String,
      required: false,
    },
  },
  name: "CompanyLogo",
  setup(props) {
    const defaultImg = "/placeholders/accord_placeholder.png";
    const imageSrc = ref(defaultImg);
    const isLoaded = ref(false);

    const handleLoad = () => {
      isLoaded.value = true;
    };

    const handleError = (event) => {
      event.target.src = defaultImg;
    };

    onMounted(() => {
      if (props.organisationImg) {
        imageSrc.value = props.organisationImg;
      }
    });

    return {
      imageSrc,
      defaultImg,
      isLoaded,
      handleLoad,
      handleError,
    };
  },
};
</script>
