<template>
  <div class="organisation mb-12">
    <LSection>
      <template #body>
        <div class="w-full flex-col">
          <div class="flex justify-between k1:mb-4 k1:flex-row flex-col">
            <div class="mr-0 k1:mr-12">
              <div class="flex justify-between content-center">
                <h1 class="heading1">
                  {{ organisation.name }}
                  <hr class="gradientLine" />
                </h1>
                <img
                  v-if="!canEdit"
                  src="/eqrcmedals_coin_level1.svg"
                  class="award w-16 h-16"
                  alt=""
                />
                <div class="content-center mt-5 ml-6 text-xs" v-if="canEdit">
                  <span> Waiting for approval! </span>
                </div>
              </div>

              <p class="text-xs text-gray-500 mb-1">
                <span> Member of EQRC since {{ relativeDate }} </span>
              </p>
              <p
                style="
                  display: -webkit-box;
                  -webkit-line-clamp: 7;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
                class="text-gray-600 text-base text-sm pb-4"
              >
                {{ organisation.description }}
              </p>
            </div>

            <div
              class="flex flex-col aspect-square justify-evenly k1:h-64 mb-12 k1:mb-0"
            >
              <CompanyLogo :organisationImg="organisation.imageUrl" />

              <div v-if="user?.__isAppAdmin" class="h8:self-end self-center">
                <button
                  v-if="canEdit"
                  class="button primary"
                  @click="onPublishOrganisation"
                >
                  Accept & Publish
                </button>
                <button
                  v-else
                  class="button secondary"
                  @click="onRetractOrganisation"
                >
                  Suspend Organisation
                </button>
              </div>
            </div>
          </div>

          <div
            class="text-sm font-medium text-center text-gray-500 border-b dark:text-gray-400 dark:border-gray-700"
          >
            <ul class="flex flex-wrap -mb-px">
              <li class="mr-2">
                <router-link
                  :to="{ name: 'organisationMembers' }"
                  class="inline-block p-4 border-b-2 rounded-t-lg"
                  :class="{
                    'active text-blue-600 dark:text-blue-500 dark:border-blue-500 border-blue-600':
                      $route.name === 'organisationMembers',
                    'hover:text-gray-600 border-gray-200 hover:border-gray-300 dark:hover:text-gray-300':
                      $route.name !== 'organisationMembers',
                  }"
                >
                  Members
                </router-link>
              </li>
              <li class="mr-2">
                <router-link
                  :to="{ name: 'organisationAbout' }"
                  class="inline-block p-4 border-b-2 rounded-t-lg"
                  :class="{
                    'active text-blue-600 dark:text-blue-500 dark:border-blue-500 border-blue-600':
                      $route.name === 'organisationAbout',
                    'hover:text-gray-600 border-gray-200 hover:border-gray-300 dark:hover:text-gray-300':
                      $route.name !== 'organisationAbout',
                  }"
                >
                  About
                </router-link>
              </li>
              <li class="mr-2">
                <router-link
                  :to="{ name: 'organisationAccords' }"
                  class="inline-block p-4 border-b-2 rounded-t-lg"
                  :class="{
                    'active text-blue-600 dark:text-blue-500 dark:border-blue-500 border-blue-600':
                      $route.name === 'organisationAccords',
                    'hover:text-gray-600 border-gray-200 hover:border-gray-300 dark:hover:text-gray-300':
                      $route.name !== 'organisationAccords',
                  }"
                >
                  Accords
                </router-link>
              </li>
            </ul>
          </div>

          <div class="body mt-12 relative">
            <router-view name="organisationTab"></router-view>
            <div class="w-full flex flex-wrap h8:flex-nowrap"></div>
          </div>
        </div>
      </template>
    </LSection>
  </div>
</template>

<script>
import LSection from "@/components/layout/LSection.vue";
import { computed, inject, ref } from "vue";
import { DELETE_ASSET } from "@/store/operations";
import { useStore } from "vuex";
import { useDocument } from "@/composables/useDocument";
import { useUserPermissions } from "@/composables/useUserPermissions";
import CompanyLogo from "@/components/accords/CompanyLogo.vue";

export default {
  name: "Organisation",
  components: {
    LSection,
    CompanyLogo,
  },
  props: {
    organisationId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    let canEdit = ref(true);
    const dayJS = inject("DAYJS");
    const dataType = "Organisation";
    const store = useStore();
    const showCreateAccordForm = ref(false);
    const { user } = useUserPermissions();
    const {
      organisation,
      loadOrganisation,
      publishOrganisation,
      retractOrganisation,
    } = useDocument(dataType, props.organisationId);

    loadOrganisation();

    canEdit = computed(() => organisation.value.isPrivate);

    const onRetractOrganisation = retractOrganisation;
    const onPublishOrganisation = publishOrganisation;

    const onDelete = () => store.dispatch(DELETE_ASSET, organisation.value);

    const relativeDate = computed(() =>
      dayJS(organisation.value.dateAdded).format("MMMM, YYYY")
    );
    return {
      organisation,
      onDelete,
      canEdit: canEdit,
      showCreateAccordForm,
      relativeDate,
      onRetractOrganisation,
      onPublishOrganisation,
      user,
    };
  },
};
</script>

<style scoped></style>
